import React from 'react';
import { Flex, SubTitle, Title } from '@themed';
import { PageMeta } from '@types';
import { styles } from './index.styles';

interface InitProps {
  content: PageMeta | undefined;
}

export const Init = ({ content }: InitProps) => (
  <Flex variant='flex.colCenter'>
    <Title as='h1' sx={styles.title}>
      {content?.title}
    </Title>
    <SubTitle as='h2' sx={styles.subTitle}>
      {content?.description}
    </SubTitle>
  </Flex>
);
